import * as React from 'react';
import * as styles from './terms.module.scss';

import Callout from '../components/v2/callout';
import Footer from '../components/v2/footer';
import Header from '../components/v2/header';
import { Link } from 'gatsby';
import SEO from '../components/seo';
import Section from '../components/section';

const CookiesPage = () => {
  return (
    <>
      <SEO
        title="Cookie Policy"
        description="Discover how Interact leverages cookies and tracking technologies to enhance your experience. Learn about our transparent approach to data usage and privacy protection in our innovative quiz-building platform."
      />
      <Header btnTheme="primary" />
      <main>
        <Section theme="white" style={{ marginTop: `4.5rem` }}>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8 text-center">
              <h1 className="heading-xl color-black">Cookie Policy</h1>
            </div>
          </div>
        </Section>
        <Section theme="white" style={{ paddingTop: '0' }} className={styles.terms}>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8">
              <p className="text-body color-black">
                <strong>Last Updated: July 26th, 2021</strong>
              </p>
              <p className="text-body color-black">
                Our <Link to="/privacy/">Privacy Statement</Link> explains how The Quiz Collective,
                Inc. (d/b/a Interact) and its respective subsidiaries (“Interact,” “we,” “us,” or
                “our,”) collect and use information from and about you when you visit our website at
                www.tryinteract.com (the “Site”) or use our products or services that link to our
                Privacy Statement (collectively, our “Services”), create an account with us or
                otherwise interact with us. This cookie policy (“Cookie Policy“) explains more about
                how we use cookies and your choices concerning cookies.
              </p>

              <p className="heading-md color-black" style={{ marginBottom: `1.5rem` }}>
                What are cookies?
              </p>
              <p className="text-body color-black">
                Cookies are text files with small amounts of information which are downloaded to the
                browser you use on your device when you visit a website. They are widely used in
                order to make websites work or work in a better, more efficient way. Websites can
                read and write the files in a cookie, enabling them to recognize you and remember
                important information that will make your use of a website more convenient (for
                example, by remembering your username or password). We do this to understand your
                use of our Site, improve your user experience and enable personalized features and
                content, and optimize our advertisements and marketing.
              </p>
              <p className="text-body color-black">
                As describe in our Privacy Statement, we use cookies and similar tracking technology
                on our Sites and Services, which may include:
              </p>
              <ul className="text-body color-black">
                <li>
                  <strong>Cookies.</strong> Cookies are small data files stored on your browser or
                  device. They may be served by the entity that operates the website you are
                  visiting (“first-party cookies”) or by other companies (“third-party cookies”).
                  For example, we partner with third-party analytics providers, like Google, which
                  set cookies when you visit our websites. This helps us understand how you are
                  using our Services so that we can improve them.
                </li>
                <li>
                  <strong>Web Beacons.</strong> A Web Beacon (or “pixel tag”) is a small tag (which
                  may be invisible to you) that may be placed on our Site’s pages or an email, which
                  collects information about your browser or device and can set cookies.
                </li>
                <li>
                  <strong>ETag, or entity tag.</strong> An Etag or entity tag is a feature of the
                  cache in browsers. It is an opaque identifier assigned by a web server to a
                  specific version of a resource found at a URL.
                </li>
                <li>
                  <strong>Embedded Scripts.</strong> An embedded script is programming code that is
                  designed to collect information about your interactions with the Site, such as the
                  links you click on.
                </li>
                <li>
                  <strong>Browser Fingerprinting.</strong> Collection and analysis of information
                  from your device, such as, without limitation, your operating system, plug-ins,
                  system fonts and other data, for purposes of identification.
                </li>
                <li>
                  <strong>Recognition Technologies.</strong> Technologies, including application of
                  statistical probability to data sets, which attempt to recognize or make
                  assumptions about users and devices e.g., that a user of multiple devices is the
                  same user.
                </li>
                <li>
                  Local storage allows data to be stored locally on your browser or device and
                  includes HTML5 local storage and browser cache.
                </li>
              </ul>
              <p className="text-body color-black">
                We will refer to cookies and similar tracking technologies simply as “cookies” for
                the remainder of this Cookie Policy.
              </p>
              <p className="text-body color-black">
                Most web browsers automatically accept cookies, but please refer to your browser
                settings if you do not want to allow cookies or only allow the use of certain types
                of cookies. You can also use your browser settings to withdraw consent to the use of
                cookies at any time or delete cookies that have already been set on your device.
                Keep in mind that by disabling certain categories of cookies, you may be prevented
                from accessing some features of Site or Services. You may find more information
                relating to opting out of cookies on your browser settings menu.
              </p>

              <p className="heading-md color-black" style={{ marginBottom: `1.5rem` }}>
                Are there different types of cookies?
              </p>
              <p className="text-body color-black">
                Typically, cookies are classified into two types, either “session cookies” - which
                do not stay on your device after you close your browser - or “persistent cookies” -
                which will usually remain on your device until you delete them, or they expire. We
                use both types of cookies on our Site and Services:
              </p>
              <ul className="text-body color-black">
                <li>
                  <strong>Session cookie.</strong> We use this type of cookie to link your actions
                  on our Site, and a different session ID is used each time you visit our Site. Each
                  session cookie will only last for the length of your browser session; all session
                  cookies are deleted after your visit. A browser session starts when you open your
                  browser window and finishes when you close it.
                </li>
                <li>
                  <strong>Persistent cookie.</strong> A persistent cookie has a longer lifetime than
                  a session cookie and lasts for the period of time specified in the cookie, which
                  varies from cookie to cookie. It is not deleted when you close your browser window
                  and will be activated every time you visit the website that created that
                  particular persistent cookie.
                </li>
              </ul>

              <p className="heading-md color-black" style={{ marginBottom: `1.5rem` }}>
                Which categories of cookies do we use and for what purposes?
              </p>
              <p className="text-body color-black">
                We use three different categories of cookies on our website:
              </p>
              <ul className="text-body color-black">
                <li>
                  <strong>Strictly necessary cookies.</strong> These cookies are essential to our
                  Site or Service and enable you to use certain features, such as remembering
                  information you have entered on forms when you move page to page on our Site. If
                  we did not use strictly necessary cookies, we may not be able to provide certain
                  services to you.
                </li>
                <li>
                  <strong>Performance cookies.</strong> Performance cookies collect information
                  about your use of our website. This enables us to improve the functionality and
                  your experience. For example, performance cookies will show us which are the most
                  visited pages on our Site, record difficulties you may have and help us evaluate
                  the effectiveness of our advertising. They do not collect information that
                  identifies you; all information is aggregated and anonymous.
                </li>
                <li>
                  <strong>Functionality cookies.</strong> These cookies allow us to remember choices
                  you make while browsing. For instance, we may store your geographic location or
                  preferences such as text size, fonts, and other customizable site elements. They
                  may also be used to keep track of what featured products or videos have been
                  viewed to avoid repetition. The information collected will not identify you and
                  cannot be used track your browsing on non-Interact websites.
                </li>
              </ul>

              <p className="heading-md color-black" style={{ marginBottom: `1.5rem` }}>
                Does Interact allow third-party cookies?
              </p>
              <p className="text-body color-black">
                Third Party Cookies are cookies placed by a third party on your device that may
                provide information to those third parties about your browsing habits. This may
                include your visits to our Site, the pages you visited and the advertisements you
                clicked, which helps identify the effectiveness of the third party’s services, your
                interests, retargeting ads and deliver ads that are more relevant to you. We do not
                control or have access to such cookies.
              </p>
              <p className="text-body color-black">
                In some cases, we may use third parties to manage our strictly necessary or
                performance cookies, but we do not allow any third party to use these cookies other
                than for the specific types of purposes listed above.
              </p>

              <p className="heading-md color-black" style={{ marginBottom: `1.5rem` }}>
                Managing cookie settings?
              </p>
              <p className="text-body color-black">
                You can decide whether or not to accept Cookies. One way you can do this is through
                your internet browser’s settings. To find out more information about Cookies,
                including information about how to manage and delete Cookies, please visit{' '}
                <a href="https://ico.org.uk/for-the-public/online/cookies/">
                  https://ico.org.uk/for-the-public/online/cookies/
                </a>{' '}
                or <a href="https://www.allaboutcookies.org/">https://www.allaboutcookies.org/</a>.
              </p>
              <p className="text-body color-black">
                To explore what Cookie setting are available to you, look in the “preferences” or
                “options” section of your browser’s menu.
              </p>

              <p className="heading-md color-black" style={{ marginBottom: `1.5rem` }}>
                Your choices
              </p>
              <p className="text-body color-black">
                You have a number of options to control or limit how we and our partners use,
                including for advertising.
              </p>
              <ul className="text-body color-black">
                <li>
                  Although most browsers and devices accept cookies by default, their settings
                  usually allow you to clear or decline cookies. If you disable cookies, however,
                  some of the features of our services may not function properly.
                </li>
                <li>
                  Our Sites use Google Analytics, a web analysis service provided by Google Inc.
                  (“Google”) which is based on Cookie technology. To prevent your data from being
                  used by Google Analytics across all websites, you can install{' '}
                  <a href="https://tools.google.com/dlpage/gaoptout">
                    Google’s opt-out browser add-on
                  </a>
                  .
                </li>
                <li>
                  For information on how our advertising partners allow you to opt out of receiving
                  ads based on your web browsing history, please visit{' '}
                  <a href="http://optout.aboutads.info/">http://optout.aboutads.info/</a>. European
                  users may opt out of receiving targeted advertising through the{' '}
                  <a href="https://www.youronlinechoices.eu/">
                    European Interactive Digital Advertising Alliance
                  </a>
                  .
                </li>
                <li>
                  To opt out of ads on Facebook, Pinterest, or Google that are targeted to your
                  interests, use your <a href="https://www.facebook.com/ads/settings">Facebook</a>, 
                  <a href="https://help.pinterest.com/en/article/personalized-ads-on-pinterest">
                    Pinterest
                  </a>
                  , or <a href="https://adsettings.google.com">Google Ads settings</a>.
                </li>
                <li>
                  Check your mobile device for settings that control ads based on your interactions
                  with the applications on your device. For example, on your iOS device, enable the
                  “Limit Ad Tracking” setting, and on your Android device, enable the “Opt out of
                  Ads Personalization” setting.
                </li>
              </ul>
              <p className="text-body color-black">
                Device IDs: If you access our mobile applications, the advertising identifier
                associated with your device or other identifiers may be recorded and used for
                purposes similar to those of cookies.
              </p>
              <p className="text-body color-black">
                For a full list of the cookies we deploy on our website and the categories they fall
                into, please see below.
              </p>

              <table className="text-body color-black" style={{ fontSize: `14px` }}>
                <tbody>
                  <tr>
                    <th>Cookie Name</th>
                    <th>Domain</th>
                    <th>Description</th>
                    <th>Expiration</th>
                    <th>Category</th>
                  </tr>
                  <tr>
                    <td>_ga</td>
                    <td>www.tryinteract.com</td>
                    <td>Used to distinguish users for Google Analytics.</td>
                    <td>2 years</td>
                    <td>Performance cookies</td>
                  </tr>
                  <tr>
                    <td>_gid</td>
                    <td>www.tryinteract.com</td>
                    <td>Used to distinguish users for Google Analytics.</td>
                    <td>24 hours</td>
                    <td>Performance cookies</td>
                  </tr>
                  <tr>
                    <td>_gat_UA-42722014-1</td>
                    <td>www.tryinteract.com</td>
                    <td>Used to persist session state users for Google Analytics.</td>
                    <td>2 years</td>
                    <td>Performance cookies</td>
                  </tr>
                  <tr>
                    <td>ajs_anonymous_id</td>
                    <td>www.tryinteract.com</td>
                    <td>Used for identifying users for tracking internal analytics.</td>
                    <td>1 year</td>
                    <td>Performance cookies</td>
                  </tr>
                  <tr>
                    <td>ajs_group_id</td>
                    <td>www.tryinteract.com</td>
                    <td>Used for identifying users for tracking internal analytics.</td>
                    <td>1 year</td>
                    <td>Performance cookies</td>
                  </tr>
                  <tr>
                    <td>ajs_user_id</td>
                    <td>www.tryinteract.com</td>
                    <td>Used for identifying users for tracking internal analytics.</td>
                    <td>1 year</td>
                    <td>Performance cookies</td>
                  </tr>
                  <tr>
                    <td>__tld__</td>
                    <td>www.tryinteract.com</td>
                    <td>Used for identifying users for tracking internal analytics.</td>
                    <td>1 year</td>
                    <td>Performance cookies</td>
                  </tr>
                  <tr>
                    <td>__hstc</td>
                    <td>www.tryinteract.com</td>
                    <td>Used to distinguish users in HubSpot.</td>
                    <td>13 months</td>
                    <td>Performance cookies</td>
                  </tr>
                  <tr>
                    <td>hubspotutk</td>
                    <td>www.tryinteract.com</td>
                    <td>Used to distinguish users in HubSpot.</td>
                    <td>13 months</td>
                    <td>Performance cookies</td>
                  </tr>
                  <tr>
                    <td>__hssc</td>
                    <td>www.tryinteract.com</td>
                    <td>Used to keep track of user sessions in HubSpot.</td>
                    <td>30 mins</td>
                    <td>Performance cookies</td>
                  </tr>
                  <tr>
                    <td>__hssrc</td>
                    <td>www.tryinteract.com</td>
                    <td>Used to keep track of user sessions in HubSpot.</td>
                    <td>Session</td>
                    <td>Performance cookies</td>
                  </tr>
                  <tr>
                    <td>intercom-id-c45rbjhd</td>
                    <td>www.tryinteract.com</td>
                    <td>Anonymous visitor identifier cookie for Intercom.</td>
                    <td>9 months</td>
                    <td>Performance cookies</td>
                  </tr>
                  <tr>
                    <td>intercom-session-c45rbjhd</td>
                    <td>www.tryinteract.com</td>
                    <td>Identifier for each unique visitor session for Intercom.</td>
                    <td>9 months</td>
                    <td>Performance cookies</td>
                  </tr>
                  <tr>
                    <td>OptanonConsent</td>
                    <td>www.tryinteract.com</td>
                    <td>Stores categories of cookies user has consented to for OneTrust.</td>
                    <td>1 year</td>
                    <td>Strictly necessary cookies</td>
                  </tr>
                  <tr>
                    <td>OptanonAlertBoxClosed</td>
                    <td>www.tryinteract.com</td>
                    <td>Stores the state of the cookie compliance solution for OneTrust.</td>
                    <td>1 year</td>
                    <td>Strictly necessary cookies</td>
                  </tr>
                  <tr>
                    <td>view.XG7RVa4LbLyhJpgy6eistK.[PAGE_ID]</td>
                    <td>api.leadpages.io</td>
                    <td>Tracks visitor sessions for LeadPages.</td>
                    <td>1 day</td>
                    <td>Strictly necessary cookies</td>
                  </tr>
                  <tr>
                    <td>centerVisitorId</td>
                    <td>js.center.io</td>
                    <td>Preserves users states across page requests.</td>
                    <td>0 days</td>
                    <td>Strictly necessary cookies</td>
                  </tr>
                  <tr>
                    <td>_ga</td>
                    <td>giphy.com</td>
                    <td>Used to distinguish users for Google Analytics.</td>
                    <td>2 years</td>
                    <td>Performance cookies</td>
                  </tr>
                  <tr>
                    <td>_gid</td>
                    <td>giphy.com</td>
                    <td>Used to distinguish users for Google Analytics.</td>
                    <td>24 hours</td>
                    <td>Performance cookies</td>
                  </tr>
                  <tr>
                    <td>_gat</td>
                    <td>giphy.com</td>
                    <td>Used to persist session state users for Google Analytics.</td>
                    <td>2 years</td>
                    <td>Performance cookies</td>
                  </tr>
                  <tr>
                    <td>__cfduid</td>
                    <td>fontawesome.com</td>
                    <td>Used for identifying users in Cloudflare.</td>
                    <td>30 days</td>
                    <td>Performance cookies</td>
                  </tr>
                </tbody>
              </table>

              <p
                id="contact-us"
                className="heading-md color-black"
                style={{ marginBottom: `1.5rem` }}>
                Contact Us
              </p>
              <p className="text-body color-black">
                If you have any questions regarding this Cookie Policy or our use of cookies, please
                contact us at:
              </p>
              <p className="text-body color-black">
                The Quiz Collective, Inc.
                <br />
                2443 Fillmore St #380-14013
                <br />
                San Francisco, CA 94115
                <br />
                privacy@tryinteract.com
              </p>
            </div>
          </div>
        </Section>
        <Callout />
      </main>
      <Footer />
    </>
  );
};

export default CookiesPage;
